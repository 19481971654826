import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./HeaderB.scss";
import { IoSearchOutline } from "react-icons/io5";
import useApiState from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { List } from "../../../utils/List";
import { filterKeyword } from "../../../utils/filterKeywords";

const academics = ["montessori", "school", "secondary", "plus_2", "bachelors"];
const highEducation = ["plus_2", "programs"];
const aboutUs = [
  "introduction",
  "mission",
  "vision",
  "principal_statement",
  "goal",
  "chairman_statement",
  "approach",
  "core_value",
  "philosophy",
  "principles",
];

const HeaderB = () => {
  const { allData } = useApiState();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  const matchedItems = filterKeyword(
    allData?.singlePageContent?.data,
    academics
  );
  const matchedItems2 = filterKeyword(
    allData?.singlePageContent?.data,
    highEducation
  );
  const matchedheaders = filterKeyword(
    allData?.singlePageContent?.data,
    aboutUs
  );
  const sortOrder = [
    "introduction",
    "mission",
    "vision",
    "goal",
    "philosophy",
    "core_value",
    "principal_statement",
    "chairman_statement",
    "approach",
    "principles",
  ];
  const academicsOrder = [
    "montessori",
    "school",
    "secondary",
    "plus_2",
    "bachelors",
  ];
  const sortedMatchedHeaders = matchedheaders
    ?.filter((item) => sortOrder.includes(item?.keyword))
    .sort((a, b) => {
      const indexA = sortOrder.indexOf(a.keyword);
      const indexB = sortOrder.indexOf(b.keyword);
      return indexA - indexB;
    });
  const sortMatchedItems = matchedItems
    ?.filter((item) => academicsOrder.includes(item?.keyword))
    .sort((a, b) => {
      const academicsA = academicsOrder.indexOf(a.keyword);
      const academicsB = academicsOrder.indexOf(b.keyword);
      return academicsA - academicsB;
    });
  const academicHeader = sortMatchedItems;
  const program = matchedItems2?.sort((a, b) => a?.id - b?.id);
  const matchedadmissions = filterKeyword(allData?.singlePageContent?.data, [
    "enrollment",
    "admission_document",
  ]);

  return (
    <header className="headerB_container">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link
            style={{ marginRight: "80px" }}
            className="navbar-brand"
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}>
            {allData?.schoolInfo?.data?.[0]?.image_link ? (
              <img
                style={{ height: "50px" }}
                src={allData?.schoolInfo?.data?.[0]?.image_link}
                alt="logo"
              />
            ) : (
              <img
                style={{ height: "50px" }}
                src={require("../../../images/nivid.png")}
                alt="logo"
              />
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => {
              setIsNavOpen(!isNavOpen);
              window.scrollTo(0, 0);
            }}>
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="aboutDropdown"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  About
                </Link>
                <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                  {sortedMatchedHeaders?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setIsNavOpen(false);
                        navigate(`/${item.keyword}`);
                      }}>
                      <Link className="dropdown-item">{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Academics
                </Link>
                <ul className="dropdown-menu">
                  <List
                    of={academicHeader}
                    render={(item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsNavOpen(false);
                          window.scrollTo(0, 0);
                          navigate(`/${item?.keyword}`);
                        }}>
                        <Link className="dropdown-item" to="#">
                          {item?.title}
                        </Link>
                      </li>
                    )}
                  />

                  <li
                    onClick={() => {
                      setIsNavOpen(false);
                      navigate(`/teams`);
                    }}
                  >
                    <Link className="dropdown-item" to="#">
                      Our Teams
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/contact"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Admissions
                </Link>
                <ul className="dropdown-menu">
                  {matchedadmissions?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setIsNavOpen(false);
                        window.scrollTo(0, 0);
                        navigate(`/${item.keyword}`);
                      }}>
                      <Link className="dropdown-item">{item.title}</Link>
                    </li>
                  ))}
                  <li>
                    <a href="#contact" className="dropdown-item">
                      Contact Us
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setIsNavOpen(false);
                      window.scrollTo(0, 0);
                      navigate("/admission_form");
                    }}>
                    <Link className="dropdown-item">Admission Form</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-nowrap"
                  href="/events"
                  aria-current="page">
                  News & Events
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/gallery" aria-current="page">
                  Gallery
                </a>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link active" to="#" aria-current="page">
                  <IoSearchOutline />
                </Link>
              </li> */}

              <li>
                {/* <a href="#contact">
                  <button type="button" className="btn contact_btn">
                    Contact
                  </button>
                </a> */}

                <a href="/admission_form">
                  <button
                    type="button"
                    className="btn contact_btn"
                    style={{ minWidth: "130px" }}>
                    Enroll Now
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderB;
